import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import SEO, { SEOMeta } from 'components/SEO/SEO';
import MinimalLayout from 'components/MinimalLayout';
import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';
import GhostButton, { Skew } from 'components/Button/GhostButton';
import Footer from 'components/Footer';
import { theme } from 'styles/styled';
import {
  translationsFactory,
  TranslationResult,
  TranslationsContext,
  useTranslations,
} from 'settings/i18n';
import useLinkCreator from 'hooks/useLinkCreator';
import { useIsRtl } from 'hooks/useCurrentLocale';
import menuLinks from 'settings/menuLinks';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.dimensions.spacingBase16 * 3}px;
`;

const Heading403 = styled(Heading)`
  margin-top: ${theme.dimensions.spacingBase16 * 2}px;
  font-size: ${theme.fontSize.pageTitle};

  @media ${theme.devices.medium} {
    margin-top: auto;
    font-size: ${theme.fontSize.headingLarge};
  }
`;

const AccessDeniedHeading = styled(Heading)`
  margin-top: ${theme.dimensions.spacingBase16 * 0.75}px;
  font-size: ${theme.fontSize.pageTitleMobile};

  @media ${theme.devices.medium} {
    margin-top: ${theme.dimensions.spacingBase16 * 0.75}px;
    font-size: ${theme.fontSize.headingBig};
  }
`;

const Text403 = styled(Text)`
  text-align: center;
  margin-top: ${theme.dimensions.spacingBase16 * 3}px;
  padding: 0 ${theme.dimensions.spacingBase16 * 1.5}px;
`;

const GoBackButton = styled(GhostButton)`
  margin-top: ${theme.dimensions.spacingBase16 * 3.5}px;
  margin-bottom: 100px;
  font-size: ${theme.fontSize.label};
`;

const AccessDeniedPageProviders = ({ data }: { data: TranslationResult }) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.homepage?.translation,
      },
    },
  });

  return (
    <TranslationsContext.Provider value={t}>
      <AccessDeniedPage metadata={data.metadata} />
    </TranslationsContext.Provider>
  );
};

const AccessDeniedPage = ({ metadata }: { metadata: SEOMeta }) => {
  const t = useTranslations();
  const createLink = useLinkCreator();
  const isRtl = useIsRtl();

  return (
    <MinimalLayout>
      <SEO url="/403" metadata={metadata || undefined} />
      <Wrapper>
        <Heading403
          isRtl={isRtl}
          size="headingLarge"
          color="primary"
          weight="medium"
          letterSpacing="normal">
          403
        </Heading403>

        <AccessDeniedHeading
          isRtl={isRtl}
          size="headingBig"
          color="dark"
          weight="light"
          letterSpacing="normal">
          {t('accessDenied')}
        </AccessDeniedHeading>

        <Text403 isRtl={isRtl}>
          {t('noAccessApologies')}
          <br />
          {t('tryOtherSiteOrGoBackToPreviousPage')}
        </Text403>

        <Link to={createLink(menuLinks.home)}>
          <GoBackButton isRtl={isRtl} skew={Skew['bottom-right']}>
            {t('goBack')}
          </GoBackButton>
        </Link>
      </Wrapper>
      <Footer />
    </MinimalLayout>
  );
};

export default AccessDeniedPageProviders;

export const query = graphql`
  query($locale: String!) {
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*403\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...FooterTranslation
      ...HeaderTranslation
      ...AccessDeniedTranslation
      ...NotFoundTranslation
      ...AboutTranslation
    }
  }
`;
